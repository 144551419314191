import { useState } from "react";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import logo from "../../Assets/Images/Ciqlercompined2024.png";
import useAppStore from "../../Store/Store.jsx";
import { CgProfile } from "react-icons/cg";
import { FaHeart } from "react-icons/fa";
import useAuthStore from "../../Store/AuthStore.jsx";

export default function NavBar() {
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [accountDropdown, setAccountDropdown] = useState(false);
  const Events = localStorage.getItem("saved_event");
  const [showMessage, setShowMessage] = useState(true);
  const { logout, isAuthenticated: isLoggedIn } = useAuthStore();

  const history = useHistory();
  const handleClick = () => {
    // console.log("Image clicked!");
    setSearchText(""); // Set searchText to empty string when image is clicked
    setTag("");
  };

  const { searchText, setSearchText, tag, setTag } = useAppStore(); // Destructure searchText and setSearchText from useAppStore

  // useEffect(() => {
  //   const handleLoggedin = () => {
  //     const isLoggedIn = JSON.parse(localStorage.getItem("loggedin"));
  //     if (isLoggedIn) {
  //       setIsLoggedIn(true);
  //     }
  //   };
  //   handleLoggedin();
  // }, []);

  return (
    <header className="flex h-20 w-full items-center px-4 md:px-6 bg-grey-100 border-b border-gray-300 ">
      <Link to="/" className="flex items-center">
        {/* Replace with your logo or icon */}
        <img
          className="max-w-full aspect-[4.76] w-[130px] hover:cursor-pointer"
          src={logo}
          alt="Logo"
          onClick={() => {
            handleClick(); // Call handleClick function when image is clicked
          }}
        />{" "}
      </Link>
      <div className="flex-1 hidden justify-center lg:flex">
        {/* Your navigation menu items for larger screens */}
        {/* <nav className="flex space-x-4">
          <Link href="#">About</Link>
          <Link href="#">Services</Link>
          <Link href="#">Contact</Link>
        </nav> */}
      </div>

      {/* Mobile navigation menu */}
      {isNavOpen && (
        <div className="lg:hidden absolute top-0 left-0 h-screen w-64 bg-grey-100 shadow-lg">
          <nav className="pt-8 px-4">
            <ul className="space-y-4">
              <li>
                <Link href="#">About</Link>
              </li>
              <li>
                <Link href="#">Services</Link>
              </li>
              <li>
                <Link href="#">Contact</Link>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </header>
  );
}
